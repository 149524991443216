<template>
  <Page ref="page" :showReturn="true">
    <el-descriptions slot="tabs" :column="3" border style="margin-bottom: 20px">
      <el-descriptions-item label="主机料号">
        {{ info.code }}
      </el-descriptions-item>
      <el-descriptions-item label="主机名称">
        {{ info.name }}
      </el-descriptions-item>
      <el-descriptions-item label="规格">
        {{ info.spec }}
      </el-descriptions-item>
    </el-descriptions>

    <template slot="search">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="配件料号" prop="code">
          <el-input placeholder="请输入" v-model="params.code"></el-input>
        </el-form-item>
        <el-form-item label="配件名称" prop="name">
          <el-input placeholder="请输入" v-model="params.name"></el-input>
        </el-form-item>
      </el-form>
    </template>

    <el-table
      :data="tableData"
      style="margin-bottom: 40px; width: 100%"
      row-key="id"
      border
      default-expand-all
      :tree-props="{ children: 'children' }"
      v-loading="loading"
    >
      <el-table-column width="50" />
      <el-table-column align="center" label="序号" type="index" width="60" />
      <el-table-column
        show-overflow-tooltip
        prop="code"
        label="配件料号"
        width="230"
      />
      <el-table-column show-overflow-tooltip prop="name" label="配件名称" />
      <el-table-column show-overflow-tooltip prop="spec" label="规格" />
      <el-table-column
        header-align="center"
        align="center"
        show-overflow-tooltip
        label="状态"
        width="80"
      >
        <template slot-scope="{ row }">
          {{ row.status | statusFilter }}
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        show-overflow-tooltip
        label="变更时间"
        width="160"
        prop="syncTime"
      />
    </el-table>
  </Page>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      tableData: [],
      params: {
        root: this.$route.query.root,
        show: 1
      }
    }
  },
  computed: {
    info() {
      return this.$store.state.bomDetails || {}
    }
  },
  filters: {
    statusFilter: function (value) {
      const obj = { 1: '正常', 2: '差异', 3: '下线' }
      return obj[value] || value || '-'
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        this.loading = true
        const list = await this.$api.bom.getBomDetail(this.params)
        this.tableData = this.$utils.transListToTreeData(list || [])
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('setState', { keyName: 'bomDetails', val: {} })
  }
}
</script>
